<splus-forms
  id="consultoriosPage"
  [submit]="submitted"
  [messageProcess]="messageProcess"
  [showMessageProcess]="showMessageProcess"
  [FormRecord]="FormRecord"
>
  <form
    [formGroup]="FormRecord"
    (ngSubmit)="onSubmit($event)"
    autocomplete="off"
    [ngClass]="{ 'formGroup-read': processType == ProcessType.view }"
  >
    <splus-forms-header [isLoading]="isLoading">
      <splus-forms-main-title
        [title]="'Consultorios'"
        [titleData]="processType == ProcessType.view ? nombreConsultorio : 'Consultorios'"
        [icon]="icon"
      >
      </splus-forms-main-title>

      <div
        *ngIf="
          processType == ProcessType.view || processType == ProcessType.edit
        "
      >
        <div class="sub-title-info">
          {{ this.FormRecord.value.nombreConsultorio | uppercase }}
          <div *ngIf="isLoading">
            <div class="k-hbox">
              <kendo-skeleton
                shape="text"
                animation="pulse"
                width="100px"
              ></kendo-skeleton>
              &nbsp;
              <kendo-skeleton
                shape="text"
                animation="pulse"
                width="70px"
              ></kendo-skeleton>
              &nbsp;
              <kendo-skeleton
                shape="text"
                animation="pulse"
                width="90px"
              ></kendo-skeleton>
            </div>
          </div>
        </div>
      </div>

      <splus-forms-toolbar [options]="options">
        <splus-forms-toolbar-buttons
          [moreOptions]="moreOptions"
          [processType]="processType"
        >
          <button
            type="Submit"
            [disabled]="isLoading"
           
            *ngIf="
              processType == ProcessType.create ||
              processType == ProcessType.edit
            "
            kendoButton
           
            rounded="small"
            themeColor="primary"
          >
          <i class="fa-solid fa-floppy-disk"></i> Guardar
          </button>
          <button
            [disabled]="isLoading"
            
            type="button"
            *ngIf="processType == ProcessType.view"
            kendoButton
           
            rounded="small"
            themeColor="primary"
            (click)="goEdit()"
          >
          <i class="fa-solid fa-pencil"></i> Editar
          </button>
          <button
            *ngIf="processType == ProcessType.edit"
            type="button"
            kendoButton
           
            rounded="small"
            themeColor="base"
            (click)="cancelEdit()"
          >
            Cancelar
          </button>
          <button
            *ngIf="processType == ProcessType.view"
            type="button"
            kendoButton
           
            rounded="small"
            themeColor="base"
            (click)="goback()"
          >
            Volver
          </button>
          <button
            *ngIf="processType == ProcessType.create"
            type="button"
            kendoButton
           
            rounded="small"
            themeColor="base"
            (click)="clearForm()"
          >
            Limpiar
          </button>
        </splus-forms-toolbar-buttons>
      </splus-forms-toolbar>
    </splus-forms-header>

    <splus-forms-fields [processType]="processType">
      <splus-forms-group [title]="'Datos Básicos'" #grupoBasico>
        <splus-field *ngVar="f.nombreConsultorio as ctrl" [splusFormControl]="ctrl">
          <kendo-textbox
            splusInput
            [splusGroup]="grupoBasico"
            type="text"
            [id]="ctrl.nombreControl"
            [formControl]="ctrl"
            (blur)="BuscarConsultorioPorNombre(ctrl.value)"
          >
          <ng-template kendoTextBoxPrefixTemplate>
            <div class="m-1">
              <i [ngClass]="icon"></i>
            </div>
            <kendo-textbox-separator></kendo-textbox-separator>
          </ng-template>
            <ng-template kendoTextBoxSuffixTemplate>
              <div *ngIf="searching" class="k-i-loading w-5">&nbsp;</div>
            </ng-template>
          </kendo-textbox>
        </splus-field>
        <splus-field
          *ngVar="f.descripcionConsultorio as ctrl"
          [splusFormControl]="ctrl"
        >
          <kendo-textarea
            splusInput
            [splusGroup]="grupoBasico"
            type="text"
            [id]="ctrl.descripcionControl"
            [formControl]="ctrl"
          >
          </kendo-textarea>
        </splus-field>
      </splus-forms-group>
      <splus-forms-group [title]="'Configuración'" #grupoConfiguracion>
        <splus-field
          *ngVar="f.fkCentroServicios as ctrl"
          [splusFormControl]="ctrl"
          #Servicios
        >
          <kendo-combobox
            #selectServicio
            splusInput
            [splusField]="Servicios"
            [splusGroup]="grupoConfiguracion"
            [formControl]="ctrl"
            [data]="serviciosRender"
            textField="nombreCentroServicios"
            valueField="id"
            [valuePrimitive]="true"
          >
          </kendo-combobox>

          <ng-template splusFieldViewModeTemplate>
            {{ selectServicio["text"] }}
          </ng-template>
        </splus-field>
        <splus-field
          *ngVar="f.fkCentroCostos as ctrl"
          [splusFormControl]="ctrl"
          #Costos
        >
          <kendo-combobox
            #selectCostos
            splusInput
            [splusField]="Costos"
            [splusGroup]="grupoConfiguracion"
            [formControl]="ctrl"
            [data]="costosRender"
            textField="nombreCentroCostos"
            valueField="id"
            [valuePrimitive]="true"
          >
          </kendo-combobox>

          <ng-template splusFieldViewModeTemplate>
            {{ selectCostos["text"] }}
          </ng-template>
        </splus-field>
        
        <splus-field
          *ngVar="f.activo as ctrl"
          [splusFormControl]="ctrl"
          [viewModeDisable]="true"
        >
          <div>
            <input
              #consultorioActivo
              type="checkbox"
              [readonly]="true"
              rounded="small"
              splusInput
              kendoCheckBox
              [formControl]="ctrl"
              [splusGroup]="grupoConfiguracion"
            />
          </div>

          <ng-template splusFieldViewModeTemplate>
            <splus-checkbox-info [checked]="consultorioActivo?.checked">
            </splus-checkbox-info>
          </ng-template>
        </splus-field>
      </splus-forms-group>
      <splus-forms-group [title]="'Procedimientos'" #grupoProcedimientos [mainLayout]="'layout-simple'">
        <splus-field
          *ngVar="f.procedimientos as ctrl"
          [splusFormControl]="ctrl"
        >
          <app-procedimientos-v3
            *ngIf="processType == ProcessType.create || DatosRecord"
            [filter]="filterProcedimiento"
            [processType]="processType"
            [grupo]="grupoProcedimientos"
            [splusFormControl]="f.procedimientos"
            [multiselect]="true"
          ></app-procedimientos-v3>
          <ng-template splusFieldViewModeTemplate> </ng-template>
        </splus-field>        
      </splus-forms-group>
    </splus-forms-fields>
  </form>
</splus-forms>
<ng-container #container *ngIf="!closePop"></ng-container>
