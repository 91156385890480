import { Component, OnDestroy, OnInit, ViewChild, ViewContainerRef, inject } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { DialogService } from '@progress/kendo-angular-dialog';
import { IntlService } from '@progress/kendo-angular-intl';
import { ProcessType, SPlusDialogCloseResult, SPlusDialogSettings, SPlusFormsDialogsService, SPlusMoreOption, SPlusOption, SPlusProcessMessageComponent } from '@saludplus/forms';
import { SplusBaseForm } from '@src/app/models/base/BaseFormComponent';
import { RoutingState } from '@src/app/services/Generals/routing.state.service';
import { SaludPlusService } from '@src/app/services/SaludPlus/saludPlus.service';
import { AuthenticationService } from '@src/app/services/Security/authentication.service';
import { UtilService } from '@src/app/services/Utilities/util.service';
import { Subject, takeUntil, Observable } from 'rxjs';
import { ConsultoriosSettings } from '../config/consultorios.settings';
import { ConsultoriosService } from '../services/consultorios.service';
import { FiltersData } from '@src/app/models/base/filterModel';
import { CentrosCostosDTO } from '@src/app/models/Administracion/CentroCostos/CentroCostosDTO';
import { CentrosServiciosDTO } from '@src/app/models/Administracion/CentroServicios/CentrosServiciosDTO';
import { ConsultoriosDTO } from '../../../../../models/Citas/ConsultoriosDTO';
import { HttpErrorResponse } from '@angular/common/http';


@Component({
    selector: 'app-consultorios',
    templateUrl: './consultorios.component.html',
    styleUrls: ['./consultorios.component.scss']
})
export class ConsultoriosComponent extends SplusBaseForm implements  OnInit, OnDestroy {

    filterProcedimiento: FiltersData[] = [
        {
            propertyName: "todas",
            propertyValue: true,
        }
    ];
    @ViewChild("container", { read: ViewContainerRef })
    public containerRef: ViewContainerRef;
    serviciosRender: CentrosServiciosDTO[] = [];
    costosRender: CentrosCostosDTO[] = [];
    override id: number = 0;
    override submitted = false;
    override isLoading = false;
    override isDeleting = false;
    nameConsultorio: string;
    searching = false;
    nombreConsultorio = "";
    // Forms
    override FormRecord: UntypedFormGroup;
    DatosRecord: ConsultoriosDTO;

    // Forms - control de los estados
    override processType: ProcessType = ProcessType.create;
    override ProcessType = ProcessType;
    override showMessageProcess: boolean;
    override messageProcess = new SPlusProcessMessageComponent();
 

    override icon = "";

    //listado de opciones --
    options: SPlusOption[] = [
        {
            id: "Listado",
            icon: "fa-solid fa-magnifying-glass",
            text: "Listado",
            link: "/citas/consultorios/listado",
        },
        {
            id: "Ayuda",
            icon: "fa-solid fa-circle-question",
            text: "Ayuda",
            action: () => {
                this.OpenDocumentacion();
            }
        }
    ];

    //listado de más opciones --
    moreOptions: SPlusMoreOption[] = [
        {
            id: "CrearConsultorio",
            showIn: ProcessType.view,
            text: "Crear nuevo consultorio",
            icon: "fa-duotone fa-plus",
            action: () => this.newRecord(),
        },
        {
            id: "EliminarConsultorio",
            showIn: ProcessType.edit,
            text: "Eliminar",
            icon: "fa-solid fa-trash-can",
            action: () => this.dialogEliminarConsultorio(),
        },
    ];
    private unsubcribe$ = new Subject<void>();
    closePop: boolean = false;

    private sPlusFormsDialogsService = inject(SPlusFormsDialogsService); 
    constructor( 
        private consultoriosSettings: ConsultoriosSettings, 
        private consultoriosService: ConsultoriosService,  
    ) {
          super()
        this.controlProcess();

        this.routingState.loadRouting();
        this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                this.controlProcess();
            }
        });
 
    }

 

    /**
     * Determina el estado de la pantalla (insercion, vista, edicion, eliminacion)
     */
    controlProcess() {
        const params = this.util.decodeURLParams(this.router.url);
        const mode = params.mode;

        if (this.route.routeConfig.path === ":id") {
            if (mode === "e") {
                this.processType = ProcessType.edit;
            } else {
                this.processType = ProcessType.view;
            }
            this.showMessageProcess = mode === "i" || mode === "u";

            this.messageProcess.title = "Confirmación";
            this.messageProcess.message = "Consultorio guardado exitosamente";
            this.messageProcess.type = "success";
            if (mode === "df") {
                this.processType = ProcessType.view;
                this.messageProcess.title = "Error";
                this.messageProcess.message =
                    "No se puede eliminar el consultorio porque tiene información asociada";
                this.messageProcess.type = "error";
                this.showMessageProcess = true;
            }
        } else {
            if (mode === "d") {
                this.processType = ProcessType.create;
                this.messageProcess.title = "Confirmación";
                this.messageProcess.message = "Consultorio eliminado exitosamente";
                this.messageProcess.type = "success";

                this.showMessageProcess = true;
            }

            if (mode === "df") {
                this.processType = ProcessType.edit;
                this.messageProcess.title = "Error";
                this.messageProcess.message =
                    "No se puede eliminar el consultorio porque tiene información asociada";
                this.messageProcess.type = "error";
                this.showMessageProcess = true;
            }
        }
    }


    ngOnInit() {
        this.icon = this.route.snapshot.data['icon'];
        this.titleService.setTitle(this.route.snapshot.data['title']);
        this.id = this.route.snapshot.params['id'];

        this.loadFormComponents();
        this.CargarDatosCombosCentrosCostos();
        this.CargarDatosCombosCentroServicios();
        if (this.id) {
            this.LoadRecord();
        }

    }

    /**
     * Abreviacion para acceder a los controles del FormRecord
     */
    public get f(): any {
        return this.FormRecord.controls;
    }

    /**
     * Componentes del formulario
     */
    loadFormComponents() {
        this.FormRecord = this.consultoriosSettings.FormComponents();
    }

    ngOnDestroy() {
        this.unsubcribe$.next();
        this.unsubcribe$.complete();
    }

    CargarDatosCombosCentrosCostos() {
        this.isLoading = true;
        this.FormRecord.disable();
        this.consultoriosService.CargarCombosCentroCostos<CentrosCostosDTO[]>().subscribe({
            next: res => {
                if (res.isSuccessful) {
                    this.FormRecord.enable();
                    this.costosRender = res.result;
                    if (this.id) {
                        this.LoadRecord();
                    } else {
                        //  this.SetDefaultValues();
                    }
                    this.isLoading = false;
                } else if (res.isError) {
                    this.FormRecord.enable();
                    let dialogSettings = {} as SPlusDialogSettings;
                    dialogSettings.appendTo = this.containerRef;
                    dialogSettings.title = "Error cargar listado";
                    dialogSettings.description = res.errorMessage;
                    dialogSettings.actions = [{ text: "Cerrar" }];
                    this.sPlusFormsDialogsService.Show(dialogSettings);
                } else {
                    this.FormRecord.enable();
                    let dialogSettings = {} as SPlusDialogSettings;
                    dialogSettings.appendTo = this.containerRef;
                    dialogSettings.title = "Error cargar listado";
                    dialogSettings.description = res.messages.join("\n");
                    dialogSettings.actions = [{ text: "Cerrar" }];
                    this.sPlusFormsDialogsService.Show(dialogSettings);
                }
            },
            error: err => {
                if (err instanceof HttpErrorResponse && err.status == 401) return;
                this.FormRecord.enable();
                console.warn(err);
                let dialogSettings = {} as SPlusDialogSettings;
                dialogSettings.appendTo = this.containerRef;
                dialogSettings.title = "Error cargar listado";
                dialogSettings.description =
                    "Error de comunicacion con el server por favor intentelo mas tarde";
                dialogSettings.actions = [{ text: "Cerrar" }];
                this.sPlusFormsDialogsService.Show(dialogSettings);
            }
        });
    }

    CargarDatosCombosCentroServicios() {
        this.isLoading = true;
        this.FormRecord.disable();
        this.consultoriosService.CargarCombosServicios<CentrosServiciosDTO[]>().subscribe({
            next: res => {
                if (res.isSuccessful) {
                    this.FormRecord.enable();
                    this.serviciosRender = res.result;
                    if (this.id) {
                        this.LoadRecord();
                    }
                    this.isLoading = false;
                } else if (res.isError) {
                    this.FormRecord.enable();
                    let dialogSettings = {} as SPlusDialogSettings;
                    dialogSettings.appendTo = this.containerRef;
                    dialogSettings.title = "Error cargar listado";
                    dialogSettings.description = res.errorMessage;
                    dialogSettings.actions = [{ text: "Cerrar" }];
                    this.sPlusFormsDialogsService.Show(dialogSettings);
                } else {
                    this.FormRecord.enable();
                    let dialogSettings = {} as SPlusDialogSettings;
                    dialogSettings.appendTo = this.containerRef;
                    dialogSettings.title = "Error cargar listado";
                    dialogSettings.description = res.messages.join("\n");
                    dialogSettings.actions = [{ text: "Cerrar" }];
                    this.sPlusFormsDialogsService.Show(dialogSettings);
                }
            },
            error: err => {
                if (err instanceof HttpErrorResponse && err.status == 401) return;
                this.FormRecord.enable();
                console.warn(err);
                let dialogSettings = {} as SPlusDialogSettings;
                dialogSettings.appendTo = this.containerRef;
                dialogSettings.title = "Error cargar listado";
                dialogSettings.description =
                    "Error de comunicacion con el server por favor intentelo mas tarde";
                dialogSettings.actions = [{ text: "Cerrar" }];
                this.sPlusFormsDialogsService.Show(dialogSettings);
            }
        });
    }

    /**
       * Guardar o actualizar el registro
       */
    async onSubmit(event) {
        //console.log("onSubmit: ", this.searching);
        event.preventDefault();
        this.submitted = true;
        if (this.FormRecord.valid) {
            if (this.FormRecord.pristine || this.searching) {
                return;
            }
            this.isLoading = true;
            if (this.processType === this.ProcessType.edit) {
                this.UpdateRecord();
            } else {
                this.SaveRecord();
            }
        } else {
            this.isLoading = false;
        }
    }

    /**
     * Guardar Cups Propio
     */
    SaveRecord() {
        this.consultoriosService
            .Guardar<number>(this.FormRecord.value)
            .subscribe({
                next: (res) => {
                    if (res.isSuccessful) {
                        this.router.navigate([`${res.result}`], {
                            queryParams: { mode: "i" },
                            relativeTo: this.route,
                        });
                    } else if (res.isError) {
                        let dialogSettings = {} as SPlusDialogSettings;
                        dialogSettings.appendTo = this.containerRef;
                        dialogSettings.title = "Error al crear el consultorio";
                        dialogSettings.description = res.errorMessage;
                        console.log(res.errorMessage);

                        dialogSettings.actions = [{ text: "Cerrar" }];
                        this.sPlusFormsDialogsService.Show(dialogSettings);
                        this.isLoading = false;
                        this.searching = false;
                    }
                },
                error: (err) => {
                    if (err instanceof HttpErrorResponse && err.status == 401) return;
                    console.warn("Error critico", err);
                    let dialogSettings = {} as SPlusDialogSettings;
                    dialogSettings.appendTo = this.containerRef;
                    dialogSettings.title = "Error al crear el consultorio";
                    dialogSettings.description =
                        "Hubo un error al intentar crear el consultorio, por favor intentelo mas tarde";
                    dialogSettings.actions = [{ text: "Cerrar" }];
                    this.sPlusFormsDialogsService.Show(dialogSettings);
                    this.isLoading = false;
                    this.searching = false;
                }

            });

    }

    /**
     * Actualizar Cups Propio
     */
    UpdateRecord() {
        //console.log("Update: ", this.searching);
        this.isLoading = true;
        this.submitted = true;
        if (this.FormRecord.valid && !this.FormRecord.pristine) {
            this.consultoriosService
                .Actualizar<boolean>(this.FormRecord.value, this.id)
                .subscribe({
                    next: (res) => {
                        if (res.isSuccessful) {
                            let dialogSettings = {} as SPlusDialogSettings;
                            dialogSettings.appendTo = this.containerRef;
                            this.FormRecord.markAsPristine();
                            this.isLoading = false;
                            this.router.navigate([], {
                                queryParams: { mode: "u" },
                                queryParamsHandling: "merge",
                            });
                        } else if (!res.isSuccessful && !res.isError) {
                            let dialogSettings = {} as SPlusDialogSettings;
                            dialogSettings.appendTo = this.containerRef;
                            dialogSettings.title = "Error al actualizar el consultorio";
                            dialogSettings.description = res.messages.join("\n");
                            dialogSettings.actions = [{ text: "Cerrar" }];
                            this.sPlusFormsDialogsService.Show(dialogSettings);
                            this.isLoading = false;
                            this.searching = false;
                        }
                        else if (res.isError) {
                            let dialogSettings = {} as SPlusDialogSettings;
                            dialogSettings.appendTo = this.containerRef;
                            dialogSettings.title = "Error al actualizar el consultorio";
                            dialogSettings.description = res.errorMessage;
                            dialogSettings.actions = [{ text: "Cerrar" }];
                            this.sPlusFormsDialogsService.Show(dialogSettings);
                            this.isLoading = false;
                            this.searching = false;
                        }
                    },
                    error: (err) => {
                        if (err instanceof HttpErrorResponse && err.status == 401) return;
                        console.warn("Error critico", err);
                        let dialogSettings = {} as SPlusDialogSettings;
                        dialogSettings.appendTo = this.containerRef;
                        dialogSettings.title = "Error al actualizar el consultorio";
                        dialogSettings.description =
                            "Hubo un error al intentar actualizar el consultorio, por favor intentelo mas tarde";
                        dialogSettings.actions = [{ text: "Cerrar" }];
                        this.sPlusFormsDialogsService.Show(dialogSettings);
                        this.isLoading = false;
                        this.searching = false;
                    }
                });
        } else {
            this.isLoading = false;
        }
    }

    /**
     * Eliminar Cups Propios
     */
    async DeleteRecord() {
        this.isDeleting = true;
        this.isLoading = true;
        this.consultoriosService.Delete<boolean>(this.id).subscribe({
            next: (res) => {
                this.isDeleting = false;
                if (res.isSuccessful) {
                    this.isLoading = false;
                    this.router.navigate(["../"], {
                        queryParams: { mode: "d" },
                        queryParamsHandling: "merge",
                        relativeTo: this.route,
                    });
                } else if (res.isError) {
                    let dialogSettings = {} as SPlusDialogSettings;
                    dialogSettings.appendTo = this.containerRef;
                    dialogSettings.title = "Error al eliminar el consultorio";
                    dialogSettings.description = res.errorMessage;
                    dialogSettings.actions = [{ text: "Cerrar" }];
                    this.sPlusFormsDialogsService.Show(dialogSettings);
                    this.isLoading = false;
                } else {
                    let dialogSettings = {} as SPlusDialogSettings;
                    dialogSettings.appendTo = this.containerRef;
                    dialogSettings.title = "Error al eliminar el consultorio";
                    dialogSettings.description = res.messages.join("\n");
                    //dialogSettings.actions = [{ text: "Cerrar" }];
                    this.sPlusFormsDialogsService.Show(dialogSettings);
                    this.isLoading = false;
                }
            },
            error: (err) => {
                if (err instanceof HttpErrorResponse && err.status == 401) return;
                console.warn("Error critico de lado del cliente", err);
                let dialogSettings = {} as SPlusDialogSettings;
                dialogSettings.appendTo = this.containerRef;
                dialogSettings.title = "Error al eliminar el consultorio";
                dialogSettings.description =
                    "Hubo un error al intentar eliminar el consultorio, por favor intentelo mas tarde";
                dialogSettings.actions = [{ text: "Cerrar" }];
                this.sPlusFormsDialogsService.Show(dialogSettings);
                this.isLoading = false;
            }
        });
    }


    /**
    * formulario en modo edicción
    */
    goEdit() {

        this.routingState.goEdit();
    }

    /**
     * Cancelar insercion o edición
    */
    cancelEdit() {
        if (this.processType === ProcessType.create) {
            this.goback();
        } else if (this.processType === ProcessType.edit) {
            // validar si se modificó el formulario visualmente
            if (!this.FormRecord.pristine) {
                let dialogSettings = {} as SPlusDialogSettings;
                dialogSettings.appendTo = this.containerRef;
                dialogSettings.title = "Cancelar edición";
                dialogSettings.isHtml = true;
                dialogSettings.description =
                    "Hay cambios pendientes por guardar. <br/>¿Estás seguro que desea cancelar la edición?";
                dialogSettings.actions = [
                    { text: "Seguir Editando" },
                    { text: "Si, Cancelar", themeColor: "primary", value: true },
                ];
                this.sPlusFormsDialogsService
                    .Show(dialogSettings)
                    .subscribe((result) => {
                        if (result instanceof SPlusDialogCloseResult) {
                            console.log("close", result);
                        } else {
                            if (result.value) {
                                this.cancelUpdate();
                            }
                        }
                    });
            } else {
                this.goback();
            }
        }
    }

    /**
     * Cancelar edicion
     */
    cancelUpdate() {
        this.FormRecord.patchValue(this.DatosRecord);
        this.FormRecord.markAsPristine();
        this.router.navigate([], {
            queryParams: { mode: null },
            queryParamsHandling: "merge",
        });
    }

    /**
     * Nuevo registro
     */
    override newRecord() {
        this.routingState.newRecord(this.route);
    }

    /**
     * Cargar el registro por su id
     */
    LoadRecord() {
        this.nameConsultorio = "";
        this.isLoading = true;
        this.consultoriosService.BuscarConsultorio<ConsultoriosDTO>(this.id).subscribe({
            next: (res) => {
                this.isDeleting = false;
                if (res.isSuccessful) {
                    //console.log("DCP..:::",this.DatosCupsPropios)
                    if (res.result) {
                        this.FormRecord.patchValue(res.result);
                        this.DatosRecord = res.result;
                        this.nameConsultorio = this.DatosRecord.nombreConsultorio;
                        this.isLoading = false;
                        this.nombreConsultorio = this.FormRecord.value.nombreConsultorio;
                        //this.DatosCupsPropios.idProcedimientoCups = this.FormRecord.value.idProcedimientoCups;
                        //this.DatosCupsPropios.otrosConceptos = this.FormRecord.value.otrosConceptos ? true : false;
                        //console.log("otrosConceptos...::: ",this.FormRecord.value.otrosConceptos ? true : false)
                    }
                } else if (res.isError) {
                    let dialogSettings = {} as SPlusDialogSettings;
                    dialogSettings.appendTo = this.containerRef;
                    dialogSettings.title = "Cargar consultorio";
                    dialogSettings.description = res.errorMessage;
                    dialogSettings.actions = [{ text: "Cerrar" }];
                    this.sPlusFormsDialogsService.Show(dialogSettings);
                    this.sPlusFormsDialogsService.Show(dialogSettings).subscribe(() => {
                        this.router.navigate(["citas/consultorios"]);
                    });
                } else {
                    let dialogSettings = {} as SPlusDialogSettings;
                    dialogSettings.appendTo = this.containerRef;
                    dialogSettings.title = "Cargar consultorio";
                    dialogSettings.description = res.messages.join("\n");
                    dialogSettings.actions = [{ text: "Cerrar" }];
                    this.sPlusFormsDialogsService.Show(dialogSettings).subscribe(() => {
                        this.router.navigate(["citas/consultorios"]);
                    });
                }
            },
            error: (err) => {
                if (err instanceof HttpErrorResponse && err.status == 401) return;
                console.warn("Error critico de lado del cliente", err);
                let dialogSettings = {} as SPlusDialogSettings;
                dialogSettings.appendTo = this.containerRef;
                dialogSettings.title = "Cargar consultorio";
                dialogSettings.description =
                    "Hubo un error al intentar cargar el consultorio, por favor intentelo mas tarde";
                dialogSettings.actions = [{ text: "Cerrar" }];
                this.sPlusFormsDialogsService.Show(dialogSettings);
            }
        });
    }
    /**
     * Limpiar formulario
     */
    clearForm() {
        this.FormRecord.reset();
        this.FormRecord.updateValueAndValidity();
        this.submitted = false;
    }

    BuscarConsultorioPorNombre(nombreConsultorio) {
        //console.log("Buscar: ", this.searching);
        if (nombreConsultorio === "" || nombreConsultorio === this.nameConsultorio) {
            return;
        }
        this.searching = true;

        this.consultoriosService.Validar<ConsultoriosDTO>(nombreConsultorio).subscribe({
            next: (res) => {
                if (res.isSuccessful) {
                    /* if (!this.id) { */
                    this.DatosRecord = {} as ConsultoriosDTO;
                    let dialogSettings = {} as SPlusDialogSettings;
                    dialogSettings.appendTo = this.containerRef;
                    dialogSettings.title = "Consultorio encontrado";
                    dialogSettings.description = `El consultorio con nombre ${nombreConsultorio} ya existe en el sistema.`;
                    dialogSettings.actions = [
                        { text: "Cancelar" },
                        { text: "Ver consultorio", themeColor: "primary", value: true },
                    ];
                    this.sPlusFormsDialogsService
                        .Show(dialogSettings).pipe(takeUntil(this.unsubcribe$))
                        .subscribe((result) => {
                            if (result instanceof SPlusDialogCloseResult || !result.value) {
                                this.searching = false;
                                this.isLoading = false;
                                if (this.route.routeConfig.path !== ":id") {
                                    this.FormRecord.controls['nombreConsultorio'].setValue("");
                                } else {
                                    this.FormRecord.controls['nombreConsultorio'].setValue(this.nameConsultorio);
                                }
                            } else {
                                if (result.value) {
                                    if (this.route.routeConfig.path === ":id") {
                                        this.processType = this.ProcessType.view;
                                        this.id = res.result.id;
                                        this.LoadRecord();
                                        this.router.navigate([
                                            "citas/consultorios/" + this.id,
                                        ]);
                                        this.searching = false;
                                    } else {
                                        this.processType = this.ProcessType.view;
                                        this.searching = false;
                                        this.router.navigate([res.result.id], {
                                            relativeTo: this.route,
                                        });
                                    }
                                }
                            }
                        });
                } else if (!res.isSuccessful && !res.isError) {
                    this.searching = false;
                } else if (res.isError) {
                    let dialogSettings = {} as SPlusDialogSettings;
                    dialogSettings.appendTo = this.containerRef;
                    dialogSettings.title =
                        "Error al consultar la informacion del consultorio";
                    dialogSettings.description = res.errorMessage;
                    dialogSettings.actions = [{ text: "Cerrar" }];
                    this.sPlusFormsDialogsService.Show(dialogSettings);
                    this.isLoading = false;
                    this.searching = false;
                }
            },
            error: (err) => {
                if (err instanceof HttpErrorResponse && err.status == 401) return;
                console.warn("Error critico", err);
                let dialogSettings = {} as SPlusDialogSettings;
                dialogSettings.appendTo = this.containerRef;
                dialogSettings.title = "Error al consultar el consultorio";
                dialogSettings.description =
                    "Hubo un error al intentar consultar el consultorio, por favor intentelo mas tarde";
                dialogSettings.actions = [{ text: "Cerrar" }];
                this.sPlusFormsDialogsService.Show(dialogSettings);
                this.isLoading = false;
                this.searching = false;
            },
        });
    }

    dialogEliminarConsultorio() {
        let dialogSettings = {} as SPlusDialogSettings;
        dialogSettings.appendTo = this.containerRef;
        dialogSettings.title = "Eliminar el consultorio";
        dialogSettings.description =
            "¿Estas seguro que desea eliminar éste registro?";
        dialogSettings.actions = [
            { text: "Cancelar" },
            { text: "Si, Eliminar", themeColor: "warning", value: true },
        ];
        this.sPlusFormsDialogsService.Show(dialogSettings).subscribe((result) => {
            if (result instanceof SPlusDialogCloseResult) {
                console.log("close", result);
            } else {
                if (result.value) {
                    this.DeleteRecord();
                }
            }
        });
    }

 

    override goback() {
        if(this.processType == ProcessType.edit){
            this.router.navigate([], {
              queryParams: { mode: null },
              queryParamsHandling: "merge",
            });
          }
          else{
            this.routingState.goback();
          }
    }

}
