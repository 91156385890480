import { Component, Inject } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { BaseSearchSetting } from "@src/app/models/base/BaseSearchSetting";
import { ControllerApiList } from "@src/app/models/SaludPlus/Controller/ControllerList";
import { FiltersRecently } from "@src/app/models/base/FiltersRecently";
import { FormSearch } from "@src/app/models/base/FormSearch";
import { UntypedFormGroup, Validators } from "@angular/forms";
import {
    TegettFormControl,
    TypeField,
} from "@src/app/models/Tegett/tegettFormControl";
import { SearchTypeFilter } from "@src/app/models/base/Search/SearchType";
import { MultipleFilter, MultipleFilterData } from "@src/app/models/Tegett/SimpleFilterData";
import { ConsultoriosService } from '../services/consultorios.service';
import { HabitacionesDTO } from '../../../../../models/Administracion/Habitaciones/HabitacionesDTO';
import { lastValueFrom } from "rxjs";
import Validation from "@src/app/components/Core/directives/generals/validatorError";

@Component({
    selector: "app-consultoriosSettings",
    template: "",
})
export class ConsultoriosSettings implements BaseSearchSetting {

    constructor(
        @Inject(Router) private router: Router,
        @Inject(ConsultoriosService) private consultoriosService: ConsultoriosService,
        @Inject(ActivatedRoute) private route: ActivatedRoute
    ) { }

    async Load() {
        return true;
    }
    /**
     * Funcion para armar la busqueda
     */
    loadFormSearch() {
        const ConsultoriosSearch = new FormSearch();
        ConsultoriosSearch.formComponents = this.FormComponents();
        ConsultoriosSearch.configSearch = {
            title: "Consultorios",
            titleIcon: "fa-solid fa-square-pen",
            apiSearch: ControllerApiList.Citas.Consultorios,
            apiSearchResult: "Listado",
            filtersRecently: this.LoadSimpleFilter(),
            buttonNewRecordText: "Nuevo consultorio",
            buttonNewRecordClick: () => this.newRecord(),

            settings: [{ objectKey: "id", sortV3: "desc", visible: false }],
            fields: [
                {
                    renderLabel: "ID",
                    objectKey: "id",
                    autoSize: true,
                },

                {
                    objectKey: "nombreConsultorio",
                    renderLabel: "Nombre",
                    pipe: "ColumnCustom", icon: "inherit",
                    click: (row) => this.goView(row),
                    autoSize: true,
                    isLink: true,
                },
                {
                    objectKey: "descripcionConsultorio",
                    renderLabel: "Descripcion",
                },
                {                    
                    objectKey: "activo",
                    renderLabel: "Estado",
                    render: (value) => `
                    <div  class="t-row">
                      <div class="item-state-circle t-color-${value == true ? 'activo' : 'inactivo'}"></div> 
                      <div>${value == true ? 'Activo' : 'Inactivo'}</div>
                    </div>`,
                },
            ],
            filters: [
                {
                    objectKey: "nombreConsultorio",
                    type: SearchTypeFilter.Text,
                },
                {
                    objectKey: "activo",
                    type: SearchTypeFilter.MultipleFilter,
                    data: this.ObtenerEstados(),
                },
            ],
        };
        return ConsultoriosSearch;
    }

    /**
     * Componentes del formulario
     */
    FormComponents(): UntypedFormGroup {
        const formRecord = new UntypedFormGroup({
            nombreConsultorio: new TegettFormControl({
                formState: "",
                validatorOrOpts: [Validators.required],
                label: "Nombre",
                typeField: TypeField.Input,
                required: true,
                nameControl: "nombreConsultorio",
            }),

            fkCentroServicios: new TegettFormControl({
                formState: "",
                label: "Centro de servicios",
                typeField: TypeField.List,
                required: false,
                nameControl: "fkCentroServicios",
            }),

            fkCentroCostos: new TegettFormControl({
                formState: "",
                label: "Centro de costos",
                typeField: TypeField.List,
                required: false,
                nameControl: "fkCentroCostos",
            }),

            procedimientos: new TegettFormControl({
                formState: "",
                label: "Listado de procedimientos CUPS",
                typeField: TypeField.List,
                required: false,
                nameControl: "procedimientos",
            }),

            descripcionConsultorio: new TegettFormControl({
                formState: "",
                label: "Descripcion",
                typeField: TypeField.Input,
                required: false,
                nameControl: "descripcionConsultorio",
            }),

            activo: new TegettFormControl({
                formState: false,
                label: "Consultorio activo",
                typeField: TypeField.CheckBox,
                required: false,
                nameControl: "activo",
            }),
        });
        return formRecord;
    }

    LoadSimpleFilter(): FiltersRecently[] {
        let filterdataRecently: FiltersRecently[] = [
            {
                field: 0,
                text: "Mis Actividades",
                separator: true,
                value: "1",
            },
            {
                field: 1,
                text: "Actualizado recientemente",
                value: "2",
            },
            {
                field: 2,
                text: "Creado recientemente",
                isDefault: true,
                value: "3",
            },
        ];
        return filterdataRecently;
    }

    newRecord() {
        this.router.navigate(["../"], { relativeTo: this.route });
    }

    /**
     * ir al registro
     * @param row linea proveniente de la busqueda
     */
    goView(row: any) {
        if (row.id) {
            this.router.navigate(["../", row.id], { relativeTo: this.route });
        }
    }

    ObtenerEstados(){
        let estados: any[] = [];
        estados.push(
          { estado: "Activo", valor: true },
          { estado: "Inactivo", valor: false }
        );
        let filter = {} as MultipleFilter;
        filter.data = [];
        filter.label = "Estado";
        filter.type = SearchTypeFilter.MultipleFilter;
    
        estados.forEach((elemt, index) => {
          let estado = {} as MultipleFilterData;
          estado.field = index;
          estado.text = elemt.estado;
          estado.value = elemt.valor;
          if (elemt.estado == "Activo") {
            estado.htmlBefore =
              '<div class="item-state-circle t-color-activo"></div>';
          }
          if (elemt.estado == "Inactivo") {
            estado.htmlBefore =
              '<div class="item-state-circle t-color-inactivo"></div>';
          }
          filter.data.push(estado);
        });
        return filter;
      }
}
