import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";



import { SearchInListComponent } from "@src/app/core/Search/search-in-list/search-in-list.component";
import { AuthGuard } from "@src/app/services/Security/guard.service";
import { ConsultoriosComponent } from "./components/consultorios.component";
import { ConsultoriosSettings } from "./config/consultorios.settings";



const routes: Routes = [
    { path: "", component: ConsultoriosComponent },
    {
      path: "listado",
      component: SearchInListComponent,
      data: { title: "Consultorios Listado" },
    },
    { path: ":id", component: ConsultoriosComponent },
  ];
  
  @NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
  })
  export class ConsultoriosRoutingModule {
    //Routing redirect
    public static RoutingConsultoriosRedirect = {
      path: "citas/editarConsultorios",
      redirectTo: "citas/consultorios",
    };
  
    //Routing principal
    public static RoutingConsultorios = {
      path: "citas/consultorios",
      loadChildren: () =>
        import("./consultorios.module").then((m) => m.ConsultoriosModule),
      canActivate: [AuthGuard],
      data: { title: `Consultorios`, icon: "fa-solid fa-square-pen",setting: ConsultoriosSettings },
    };
  }