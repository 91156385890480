import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SaludPlusApiResponse } from '@src/app/models/base/SaludplusApiResponse';
import { ConsultoriosDTO } from '@src/app/models/Citas/ConsultoriosDTO';
import { ControllerApiList } from '@src/app/models/SaludPlus/Controller/ControllerList';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ConsultoriosService {

  constructor(private http: HttpClient) { }
  /**
   * Guardar
   * @param Datos Datos del recurso
   */
  Guardar<T>(Datos: ConsultoriosDTO) {      
    return this.http.post<SaludPlusApiResponse<T>>(
      `${ControllerApiList.Citas.Consultorios}`,
      Datos
    );
  }
  /**
   * Actualizar
   * @param DatosConsultorio datos  a actualizar
   * @param idConsultorio id del idcentroCostos a actualizar
   */
  Actualizar<T>(
    DatosConsultorio: ConsultoriosDTO,
    idConsultorio: number
  ) {
    DatosConsultorio.id = idConsultorio;
    const consultorio = this.http.put<SaludPlusApiResponse<T>>(
      `${ControllerApiList.Citas.Consultorios}`,
      DatosConsultorio
    );

    return consultorio;
  }

  BuscarConsultorio<T>(
    idConsultorio: number
  ): Observable<SaludPlusApiResponse<T>> {
    let params = new HttpParams().set("idConsultorio", idConsultorio);
    return this.http.get<SaludPlusApiResponse<T>>(
      `${ControllerApiList.Citas.Consultorios}`,
      {
        params: params,
      }
    );
  }

  Delete<T>(idConsultorio: number): Observable<SaludPlusApiResponse<T>> {
    let params = new HttpParams().set("idConsultorio", idConsultorio);
    return this.http.delete<SaludPlusApiResponse<T>>(
      `${ControllerApiList.Citas.Consultorios}`,
      {
        params: params,
      }
    );
  }

  /**
   * Buscar centro de costo por su nombre
   * @param codigo
   */
  Validar<T>(codigo: string) {
    let params = new HttpParams().set("search", codigo);
    return this.http.get<SaludPlusApiResponse<T>>(
      `${ControllerApiList.Citas.Consultorios}/Validar`,
      {
        params: params,
      }
    );
  }

  CargarCombosCentroCostos<T>(): Observable<SaludPlusApiResponse<T>> { 
    return this.http.get<SaludPlusApiResponse<T>>(
      `${ControllerApiList.General.General}/CentrosCostos`,
    );
  }

  CargarCombosServicios<T>(): Observable<SaludPlusApiResponse<T>> { 
    return this.http.get<SaludPlusApiResponse<T>>(
      `${ControllerApiList.General.General}/CentrosServicios`,
    );
  }
}
